import logo from './logo.svg';
import './App.css';
import { API_BASE_URL } from './config';
import React, { useEffect, useState } from "react";

function App() {
  const url = API_BASE_URL + "/api/ping";
  const [data, setData] = useState([]);

  const fetchInfo = () => {
    return fetch(url, {headers: { 'accept' : 'application/json' }})
      .then((res) => res.json())
      .then((d) => setData(d))
  }
  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>
          🚧 <code>sameer.ai</code> is under construction!!! 🚧
        </p>
        <a
          className="App-link"
          href="https://sameer.streamlit.app/"
          rel="noopener noreferrer"
        >
          Chat with my resume in the meantime!
        </a>
        <p>
        API_BASE_URL: {API_BASE_URL} <br />
        API Ping: {data.success}
        </p>
      </header>
    </div>
  );
}

export default App;
